<template>
    <div class="layout-empty">
        <main class="layout-empty__content">
            <slot />
        </main>
    </div>
</template>

<style lang="scss">
.layout-empty {
    min-height: 100dvh;
    display: grid;

    max-width: 768px;
    // max-width: 844px;

    margin: auto;
    background-image: url('~/assets/bg/bg-blur-violett-orange.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;

    &__content {
        padding: 24px;
        padding-bottom: 60px;
    }
}
</style>
